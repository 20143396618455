import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";

// Array com as atualizações
const updates = [
  {
    id: "update-1",
    title: "Correções e melhorias",
    message:
      "Tema de natal retirado; Calendario atualizado; Padronização de elementos e páginas; Mudanças na página de solicitações da empresa; Nova barra de progresso compacta; Submenus na barra lateral (menu no topo) agora abre e se fecham sozinhos ao passar o mouse. Mantenha sempre seu Estagius autualizado!",
  },
];

export const UpdateModal: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(true);

  // Verifica se há atualizações não vistas
  useEffect(() => {
    const seenUpdates = JSON.parse(
      localStorage.getItem("seenUpdates3") || "[]"
    );
    const newUpdates = updates.filter(
      (update) => !seenUpdates.includes(update.id)
    );

    if (newUpdates.length === 0) {
      setIsModalVisible(false);
    }
  }, []);

  // Fecha o modal e salva as atualizações vistas
  const closeModal = () => {
    const seenUpdates = updates.map((update) => update.id);
    localStorage.setItem("seenUpdates3", JSON.stringify(seenUpdates));
    setIsModalVisible(false);
  };

  if (!isModalVisible) return null;

  return (
    <Modal centered size="lg" show={isModalVisible} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Atualização - Update 1.5</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        {updates.map((update) => (
          <div key={update.id} className="mb-3">
            <h4>{update.title}</h4>
            <p>{update.message}</p>
          </div>
        ))}
      </Modal.Body>

      <Modal.Footer className="justify-content-between">
        <Button
          target="blank"
          className="d-none"
          href="https://docs.google.com/forms/d/e/1FAIpQLScSJkkn8ZoShWemUPjmHzF1d-7BCeVIPLjKhRo-z620wUuHFg/viewform?usp=dialog"
          variant="primary"
        >
          Acessar formulário
        </Button>
        <Button variant="danger" onClick={closeModal}>
          Fechar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
