import React from "react";
import { Container, Card, Button, Row, Col, Accordion } from "react-bootstrap";
import {
  FaFilePdf,
  FaFileContract,
  FaUserMinus,
  FaBuilding,
  FaCalendarCheck,
  FaChartBar,
  FaChevronDown,
} from "react-icons/fa";
import Vaga from "../../../assets/solicitacoes/orientacao_processo_seletivo_3.0.pdf";
import Contrato from "../../../assets/solicitacoes/carta_de_orientacao.pdf";
import Cartao from "../../../assets/solicitacoes/cartao-pre-cadastro.png";
import ProcessoSeletivo from "../../../assets/solicitacoes/Slide-para-o-processo-seletivo.pptx";
import Cartilha from "../../../assets/solicitacoes/Cartilha-financeira.pdf";

import { Link } from "react-router-dom";

import "./style.css";


const buttonInfos = [
  /*{
        label: "Solicitar Vaga",
        description: (
            <>
                <p>Este campo permite que sua empresa manifeste interesse em candidatos para vagas de estágio disponíveis, contribuindo para o fortalecimento das equipes e complementando os currículos dos candidatos.</p>
                <p>Por favor, preencha as informações relevantes ao perfil desejado. Certifique-se de revisar todos os dados antes de enviar a solicitação. Nossa equipe entrará em contato para agendar entrevistas com os candidatos que melhor se alinhem às suas necessidades.</p>
                <p><strong>Fique atento às Regras do Processo Seletivo. Baixe o PDF com as orientações completas.</strong></p>
            </>
        ),
        variant: "primary",
        shadow: "var(--primary)",
        link: "https://form.jotform.com/drestagioof/solicitacao-de-vagas",
        pdfLink: Vaga,
        icon: <FaClipboardCheck />
    },
    */
  {
    label: "Solicitar contrato de estágio",
    description: (
      <>
        <p>
          Utilize este campo para que sua empresa solicite contratos de estágio,
          assegurando a formalização e regularização dos estagiários conforme as
          normas vigentes.
        </p>
        <p>
          Verifique todas as informações antes de enviar a solicitação. Nossa
          equipe entrará em contato para concluir o processo e fornecer o
          suporte necessário.
        </p>
        <p>
          <strong>
            Fique atento às regras de contratação. Baixe o PDF com as
            orientações completas.
          </strong>
        </p>
      </>
    ),
    variant: "success",
    shadow: "var(--green)",
    link: "/solicitacoes/novo",
    pdfLink: Contrato,
    icon: <FaFileContract size={18} />,
  },
  {
    label: "Solicitar desligamento de estágio",
    description: (
      <>
        <p>
          Utilize este campo para solicitar o desligamento formal de estagiários
          da sua empresa. Por favor, forneça as informações necessárias, como o
          nome do estagiário, data prevista para o desligamento, e o motivo do
          encerramento do estágio.
        </p>
        <p>
          Certifique-se de revisar todos os dados antes de enviar a solicitação.
          Nossa equipe processará o pedido e entrará em contato para confirmar
          os próximos passos.
        </p>
      </>
    ),
    variant: "danger",
    link: "https://form.jotform.com/241766196179672",
    icon: <FaUserMinus size={18} />,
  },
  {
    label: "Inclusão de CNPJ",
    description: (
      <>
        <p>
          Insira o CNPJ da sua empresa neste campo. O CNPJ é um identificador
          essencial para formalizar contratos, solicitações e outras operações
          relacionadas à sua empresa.
        </p>
        <p>
          Verifique se o número inserido está correto e atualizado antes de
          enviar.
        </p>
      </>
    ),
    variant: "warning",
    link: "https://form.jotform.com/233025932642654",
    icon: <FaBuilding size={18} />,
  },
];

const MaterialInfo = [
  {
    title: "Cartão Pré-cadastro",
    description:
      "Deve ser entregue ao candidato que for selecionado para a vaga de estágio. Com este folder, ele vai entrar em contato com a nossa equipe.",
    file: Cartao,
    buttonText: "Ver Cartão",
  },
  {
    title: "Slide para o processo seletivo",
    description: "Pode ser usado no momento da sua seleção de candidatos.",
    file: ProcessoSeletivo,
    buttonText: "Ver Slide",
  },
  {
    title: "Cartilha Financeira",
    description: "Tire suas principais dúvidas sobre questões financeiras.",
    file: Cartilha,
    buttonText: "Ver Cartilha",
  },
];

const RenderButtonCard = ({
  label,
  description,
  variant,
  link,
  pdfLink,
  icon,
}: {
  label: string;
  description: React.ReactNode;
  variant: string;
  link: string;
  pdfLink?: string;
  icon: React.ReactNode;
}) => (
  <Card>
    <Accordion.Toggle
      as="div"
      eventKey={label}
      className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
      style={{
        padding: "15px",
        cursor: "pointer",
        border: "solid 1px var(--slate-300)",
        borderRadius: "var(--br-md)",
      }}
    >
      <div className="d-flex align-items-center">
        <div className="mr-3">{icon}</div>
        <p>{label}</p>
      </div>
      <div className="d-flex gap-4">
        {link && (
          <Button variant={variant} href={link} target="_blank">
            Acessar
          </Button>
        )}
        {pdfLink && (
          <Button variant="secondary" href={pdfLink} target="_blank">
            <FaFilePdf />
          </Button>
        )}
        <FaChevronDown />
      </div>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={label}>
      <Card.Body>{description}</Card.Body>
    </Accordion.Collapse>
  </Card>
);

const RenderMaterialCard = ({ title, description, file, buttonText }) => (
  <Card style={{ boxShadow: "none" }}>
    <Accordion.Toggle
      as="div"
      eventKey={title}
      className="d-flex bg-secondary-c justify-content-between align-items-center mb-2"
      style={{
        padding: "15px",
        cursor: "pointer",
        border: "solid 1px var(--slate-300)",
        borderRadius: "var(--br-md)",
      }}
    >
      <p>{title}</p>
      <div className="d-flex gap-4">
        <Button
          style={{ width: "120px" }}
          variant="secondary"
          href={file}
          target="_blank"
        >
          {buttonText}
        </Button>
        <FaChevronDown />
      </div>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={title}>
      <Card.Body>
        <p>{description}</p>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
);

export const Solicitations: React.FC = () => {
  return (
    <Container fluid>
      <Card className="solicitations-home">
        <div className="text-center mb-4">
          <h1>Bem-vindo ao Estagius</h1>
          <p>
            Gerencie todas as solicitações e informações de estagiários
            diretamente pelo sistema.
          </p>
        </div>
        <Row>
          <Col md={6}>
            <h2 className="text-center">Ações</h2>
            <hr />
            <Accordion>
              {buttonInfos.map((info, index) => (
                <RenderButtonCard key={index} {...info} />
              ))}
            </Accordion>
          </Col>

          <Col md={6}>
            <h2 className="text-center">Material de Apoio</h2>
            <hr />
            <Accordion>
              {MaterialInfo.map((info, index) => (
                <RenderMaterialCard key={index} {...info} />
              ))}
            </Accordion>
          </Col>
        </Row>

        <Row className="mt-5 text-center">
          <Col lg={12}>
            <h2>Acompanhe as Presenças dos Estagiários</h2>
            <p>
              O Estagius oferece uma funcionalidade completa para que sua
              empresa acompanhe de perto as presenças dos estagiários. Através
              do sistema, você pode visualizar o histórico de presenças,
              verificar datas específicas e conferir a quantidade de presenças e
              faltas de cada estagiário.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col lg={6}>
            <Card className="text-center hover-card">
              <Card.Body className="d-flex flex-column" style={{ gap: "16px" }}>
                <FaCalendarCheck size={70} />
                <h5>Verifique as presenças detalhadas dos estagiários.</h5>
                <Button variant="primary" href="/relatorios/empresas/presencas">
                  Presenças
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card className="text-center hover-card">
              <Card.Body className="d-flex flex-column" style={{ gap: "16px" }}>
                <FaChartBar size={70} />
                <h5>Acompanhe a contagem de presenças e faltas.</h5>
                <Button
                  variant="secondary"
                  href="/relatorios/empresas/contagem"
                >
                  Contagem de presenças
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card>
    </Container>
  );
};
