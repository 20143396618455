import pdfMake from "pdfmake/build/pdfmake";
// eslint-disable-next-line import/no-extraneous-dependencies
import pdfFonts from "pdfmake/build/vfs_fonts";
import { GetReportOcorrenciaResponse } from "src/types/ReportTypes";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const handleGenerateOcorrenciaPdf = (
  data: GetReportOcorrenciaResponse
) => {
  
  const docDefinition = {
    pageSize: "A4",
    pageMargins: [40, 66, 40, 20],
    content: [
      {
        text: "OCORRÊNCIA DE DESLIGAMENTO \n",
        bold: true,
        alignment: "center",
        fontSize: 14,
        margin: [0, 0, 0, 10],
      },
      {
        text: [
          `O (a) aluno(a)  ${data.name} foi desligado no dia ${data.shutdownDate}. Seu último acesso foi em ${data.lastAttendance} no curso ${data.courseName} no módulo ${data.totalModule}. \n\n`,
        ],
    
        alignment: "center",
      },

      {
        text: [
          `O(a) aluno(a) possui  ${data.totalAttendacesPositive} presenças e ${data.totalAttendacesNegative}  faltas.\n\n`,
        ],
     
        alignment: "center",
      },

      {
        text: [`Fortaleza/Ce,${data.shutdownDate} \n\n\n`],
      
        alignment: "right",
      },
    ],
    defaultStyle: {
      fontSize: 11,
      margin: [20, 50],
    },
  };

  pdfMake.createPdf(docDefinition).open();
};
