import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { parseISO } from "date-fns";

import api from "src/services/axios";

import Input from "../../../components/Input";
import InputMoney from "../../../components/InputMoney";
import InputDate from "../../../components/InputDate";
import Textarea from "../../../components/Textarea";
import Select from "../../../components/Select";

import schema from "./schema";
import { yupResolver } from "@hookform/resolvers";
import solicitationService from "src/services/SolicitationService";
import {
  DefaultValuesFormAnalysisSolicitationCompanyEnum,
  DefaultValuesFormAnalysisSolicitationCompanyType,
  ResponseFetchOneSolicitation,
} from "src/types/SolicitationsTypes";
import { SelectOption } from "src/types/GlobalTypes";
import InputMask from "../../../components/InputMask";

const higherEducationOptions = [
  {
    value: true,
    label: "Sim",
  },
  {
    value: false,
    label: "Não",
  },
];

const ContractSolicitationAnalysis: React.FC = () => {
  const history = useHistory();
  const params = useParams();

  const [loading, setLoading] = useState<boolean>(false);
  const [solicitation, setSolicitation] =
    useState<ResponseFetchOneSolicitation | null>(null);

  const [supervisorsCompaniesOptions, setSupervisorsCompaniesOptions] =
    useState<SelectOption[]>([]);
  const [supervisorsEducationalOptions, setSupervisorsEducationalOptions] =
    useState<SelectOption[]>([]);

  const [coursesOptions, setCourses] = useState<SelectOption[]>([]);
  const [currentActivies, setCurrentActivies] = useState([]);

  const { handleSubmit, control, watch, errors, setValue } =
    useForm<DefaultValuesFormAnalysisSolicitationCompanyType>({
      reValidateMode: "onSubmit",
      resolver: yupResolver(schema),
      defaultValues: {
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.TRAINEE_ID]: null,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.EDUCATIONAL_INSTITUTION_ID]:
          null,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.COMPANY_ID]: null,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.START_VALIDITY]:
          undefined,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.END_VALIDITY]:
          undefined,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.DURATION]: 0,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.WORK_ACTIVITIES]: "",
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.INTERNSHIP_SCHOLARSHIP_VALUE]: 0,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.TRANSPORTATION_ASSISTANCE_VALUE]: 0,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.COURSE_ID]: null,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.ACTIVIES_ID]: null,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.INSURANCE_NUMBER]: "",
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.IS_HIGHER_EDUCATION]:
          {
            value: false,
            label: "Não",
          },
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_COMPANY]:
          null,
        [DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_EDUCATION]:
          null,
      },
    });

  const activiesId = watch(
    DefaultValuesFormAnalysisSolicitationCompanyEnum.ACTIVIES_ID
  );
  useEffect(() => {
    const fetchSupervisorsAndSolicitation = async () => {
      try {
        const solicitationResponse =
          await solicitationService.fetchOneSolicitation(params.id);
        const solicitationData = solicitationResponse;

        setSolicitation(solicitationData);

        const [supervisorsCompaniesResponse, supervisorsEducationalResponse] =
          await Promise.all([
            api.get(
              `/supervisors/companies?company_id=${solicitationData.company_id}`
            ),
            api.get(
              `/supervisors/educational_institutions?educational_institution_id=${solicitationData.educational_institution_id}`
            ),
          ]);

        const optionsCompanies = supervisorsCompaniesResponse.data.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setSupervisorsCompaniesOptions(optionsCompanies);

        const optionsEducational = supervisorsEducationalResponse.data.map(
          (item) => ({
            value: item.id,
            label: item.name,
          })
        );
        setSupervisorsEducationalOptions(optionsEducational);

        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.START_VALIDITY,
          parseISO(solicitationData.start_validity.substring(0, 10))
        );

        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.DURATION,
          Number(solicitationData.duration)
        );

        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.CONTACT,
          solicitationData.contact
        );

        setValue(DefaultValuesFormAnalysisSolicitationCompanyEnum.COMPANY_ID, {
          value: solicitationData.company.id,
          label: solicitationData.company?.company_name,
        });

        setValue(DefaultValuesFormAnalysisSolicitationCompanyEnum.COURSE_ID, {
          value: solicitationData.course?.id,
          label: solicitationData.course?.name,
        });

        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.IS_HIGHER_EDUCATION,
          higherEducationOptions.find(
            (item) => item.value === solicitationData.is_higher_education
          )
        );

        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.INTERNSHIP_SCHOLARSHIP_VALUE,
          solicitationData.internship_scholarship_value
        );
        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.TRANSPORTATION_ASSISTANCE_VALUE,
          solicitationData.transportation_assistance_value
        );

        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_COMPANY,
          optionsCompanies.find(
            (item) => item.value === solicitationData.supervisor_company_id
          )
        );

        setValue(
          DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_EDUCATION,
          optionsEducational.find(
            (item) =>
              item.value ===
              solicitationData.supervisor_educational_institution_id
          )
        );
      } catch (error) {
        toast.error("Erro ao carregar dados");
      }
    };

    fetchSupervisorsAndSolicitation();
  }, [params.id, setValue]);

  useEffect(() => {
    api.get("courses").then((response) => {
      setCourses(
        response.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    });
  }, []);

  const onSubmit = (data) => {
    const dataContract = {
      solicitation_id: solicitation?.id,
      start_validity: data.start_validity,
      end_validity: data.end_validity,
      duration: data.duration,
      course_id: data?.course_id?.value,
      supervisor_company_id: data.supervisor_company?.value,
      supervisor_educational_institution_id: data.supervisor_education?.value,
      work_activities: data.work_activities,

      trainee_id: solicitation?.trainee?.id,
      company_id: solicitation?.company_id,
      educational_institution_id: solicitation?.educational_institution_id,
      internship_scholarship_value: data.internship_scholarship_value,
      transportation_assistance_value: data.transportation_assistance_value,
      insurance_number: data.insurance_number,
    };

    api
      .post("/contracts/generate", dataContract)
      .then(() => {
        toast.success("Contrato estabelecido com sucesso!");
        history.push("/contratos/solicitacoes");
      })
      .catch(() => {
        toast.error("Houve um erro ao estabelecer o contrato!");
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (solicitation?.course_id) {
      api
        .get(`courseActivities?course_id=${solicitation?.course_id}`)
        .then((response) => {
          setCurrentActivies(
            response.data.map((item) => {
              return {
                value: item.id,
                label: item.description,
              };
            })
          );
        });
    }
  }, [solicitation]);

  useEffect(() => {
    if (activiesId) {
      let text: string = "";

      if (activiesId.length === 1) {
        text = activiesId[0].label;
      } else {
        // eslint-disable-next-line array-callback-return
        activiesId.map((element, index) => {
          if (index === activiesId.length) {
            text = `${text} e ${element.label}`;
          } else {
            text = `${text}, ${element.label}`;
          }
        });
      }

      setValue(
        DefaultValuesFormAnalysisSolicitationCompanyEnum.WORK_ACTIVITIES,
        text
      );
    }
  }, [activiesId]);

  return (
    <Container fluid>
      <Card>
        <Card.Header as="h5">Análise de solicitação</Card.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            {solicitation && (
              <div>
                <h5>Dados do estágiario:</h5>
                <strong> Nome: </strong> {solicitation?.trainee_name}
                <strong> Cpf: </strong>
                {solicitation?.trainee_document}
              </div>
            )}
            <hr />
            <br />
            {solicitation && (
              <div>
                <h5>Dados da empresa:</h5>
                <strong> Nome: </strong> {solicitation?.company?.company_name}
                <strong> CNPJ: </strong>
                {solicitation?.company?.cnpj}
              </div>
            )}
            <hr />
            <br />
            {solicitation && (
              <div>
                <h5>Dados da instituição de ensino:</h5>
                <strong> Nome: </strong>
                {solicitation?.educational_institution?.name}
              </div>
            )}
            <hr />

            <Form.Row>
              <Controller
                as={InputDate}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.START_VALIDITY
                }
                label="Período vigência - Início"
                md="4"
                required
                errorMessage={errors.start_validity?.message}
              />
              <Controller
                as={InputDate}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.END_VALIDITY
                }
                label="Período vigência - Fim"
                md="4"
                errorMessage={errors.end_validity?.message}
                required
              />
              <Controller
                as={Input}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.INSURANCE_NUMBER
                }
                label="Nº do seguro"
                md="4"
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={InputMoney}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.INTERNSHIP_SCHOLARSHIP_VALUE
                }
                label="Valor da bolsa"
                md="4"
                error={errors.internship_scholarship_value?.message}
                required
              />
              <Controller
                as={InputMoney}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.TRANSPORTATION_ASSISTANCE_VALUE
                }
                label="Auxilio transporte"
                md="4"
                error={errors.transportation_assistance_value?.message}
                required
              />
              <Controller
                as={Input}
                type="number"
                control={control}
                name={DefaultValuesFormAnalysisSolicitationCompanyEnum.DURATION}
                errorMessage={errors.duration?.message}
                label="Jornada em horas"
                md="4"
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.IS_HIGHER_EDUCATION
                }
                label="É ensino superior ?"
                md="6"
                options={higherEducationOptions}
                errorMessage={errors.is_higher_education?.message}
                required
              />
              <Controller
                as={InputMask}
                mask="cel"
                control={control}
                name={DefaultValuesFormAnalysisSolicitationCompanyEnum.CONTACT}
                label="Telefone"
                md="6"
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.COURSE_ID
                }
                label="Qual o curso ?"
                md="12"
                errorMessage={errors.course_id?.message}
                options={coursesOptions}
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.ACTIVIES_ID
                }
                label="Qual as atividades ?"
                md="12"
                options={currentActivies}
                isMulti
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Textarea}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.WORK_ACTIVITIES
                }
                label="Atividades"
                md="12"
                required
              />
            </Form.Row>
            <Form.Row>
              <Controller
                as={Select}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_EDUCATION
                }
                label="Qual supervisor da educação ?"
                md="6"
                options={supervisorsEducationalOptions}
                required
              />
              <Controller
                as={Select}
                control={control}
                name={
                  DefaultValuesFormAnalysisSolicitationCompanyEnum.SUPERVISOR_COMPANY
                }
                label="Qual a supervisor da empresa ?"
                md="6"
                options={supervisorsCompaniesOptions}
                required
              />
            </Form.Row>
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button className="mr-3 button-primary" type="submit">
                {loading ? "Carregando..." : "Salvar"}
              </Button>
              <Button
                onClick={() => history.goBack()}
                className="button-secondary-outline"
              >
                Cancelar
              </Button>
            </Form.Row>
          </Card.Footer>
        </Form>
      </Card>
    </Container>
  );
};

export default ContractSolicitationAnalysis;
