import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import {
  RiFileListFill,
  RiMailUnreadFill,
  RiLogoutBoxRFill,
  RiMoonClearFill,
  RiSunFill,
  RiFileCheckFill,
} from "react-icons/ri";

import { signOut } from "src/store/modules/auth/actions";
import Avatar from "../../../assets/Images/Avatar.png";
import { Footer } from "../../../components/Footer";
import { Header } from "../../../components/Header";
import MobileWarningModal from "../../../components/MobileWarningModal/MobileWarningModal";
import QuickActions from "../../../components/QuickActions";

function CompanyLayout({ children }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const location = useLocation();

  const handleSignOut = () => {
    dispatch(signOut(true));
  };

  return (
    <div className="d-flex">
      <nav className="sidebar" id="sidebar">
        <div className="sidebar__container">
          <div className="sidebar__user">
            <div className="sidebar__info">
              <h3>{profile.name}</h3>
              <span className="financial-access">Empresa</span>
            </div>
          </div>

          <div className="sidebar__content">
            <div className="sidebar__list">
              <Link
                to="/solicitacoes/feitas"
                className={`sidebar__link ${
                  location.pathname === "/solicitacoes/feitas"
                    ? "active-link"
                    : ""
                }`}
              >
                <RiMailUnreadFill />
                <span>Minha solicitações</span>
              </Link>


             


              <Link
                to="/solicitacoes"
                className={`sidebar__link ${
                  location.pathname === "/solicitacoes" ? "active-link" : ""
                }`}
              >
                <RiMailUnreadFill />
                <span>Solicitações</span>
              </Link>

              <Link
                to="/relatorios/empresas/presencas"
                className={`sidebar__link ${
                  location.pathname === "/relatorios/empresas/presencas"
                    ? "active-link"
                    : ""
                }`}
              >
                <RiFileCheckFill />
                <span>Presenças</span>
              </Link>

              <Link
                to="/relatorios/empresas/contagem"
                className={`sidebar__link ${
                  location.pathname === "/relatorios/empresas/contagem"
                    ? "active-link"
                    : ""
                }`}
              >
                <RiFileListFill />
                <span>Contagem de Presenças</span>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div className="d-flex flex-column w-100 ">
        <MobileWarningModal />
        <QuickActions />
        <Header />
        <div className="h-100vh mt-4">{children}</div>
        <Footer />
      </div>
    </div>
  );
}

export default CompanyLayout;
