import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

export default function footer(currentPage: number, pageCount: number) {
      return {
        columns: [
          {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: "left",
            margin: [40, 10],
          },
          {
            text: `Gerado em: ${format(new Date(), "dd/MM/yyyy HH:mm", {locale: ptBR})}`,
            alignment: "right",
            margin: [0, 10, 40, 0],
          },
        ],
      };
    }