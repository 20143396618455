import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Importações do react-bootrap
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import { useDropzone } from "react-dropzone";
import api from "src/services/axios";
import { toast } from "react-toastify";
import { CiImport } from "react-icons/ci";
import { Col, Row } from "react-bootstrap";
import { ProfileReduxState } from "src/types/GlobalTypes";
import { useSelector } from "react-redux";

const DocumentsCompanies: React.FC = () => {
  const profile: ProfileReduxState = useSelector((state) => state.user.profile);

  const params = useParams();

  const [currentFile, setCurrentFile] = useState(null);

  const [files, setFiles] = useState<any>([]);

  const [newFiles] = useState<any>([]);

  useEffect(
    () => () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      newFiles.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files, newFiles]
  );

  const handleGetDocuments = useCallback(() => {
 
    if (profile?.company_id) {
      api
        .get(
          `/companies/my_folders/${params.folderId}/documents`
        )
        .then((response) => {
          setFiles(
            response.data.map((file) => {
              return {
                id: file.id,
                file_id: file.files.id,
                type: file.files.content_type,
                url: file.files.url,
                name: file.files.name,
              };
            })
          );
        })
        .catch(() => {
          toast.error("Houve um erro ao tentar recuperar os documentos");
        });
    } else {
      api
        .get(
          `/companies/documents?company_id=${params.id}&folder_id=${params.folderId}`
        )
        .then((response) => {
          setFiles(
            response.data.map((file) => {
              return {
                id: file.id,
                file_id: file.files.id,
                type: file.files.content_type,
                url: file.files.url,
                name: file.files.name,
              };
            })
          );
        })
        .catch(() => {
          toast.error("Houve um erro ao tentar recuperar os documentos");
        });
    }
  }, [params.id, params.folderId, profile ]);

  useEffect(() => {
  
      handleGetDocuments();
  
  }, [handleGetDocuments]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: ["image/*", ".pdf"],
    onDrop: async (acceptedFiles) => {
      try {
        const formData = new FormData();
        formData.append("image", acceptedFiles[0]);

        await api.post(
          `/files?type_upload=company_document_upload&file_id=${currentFile}`,
          formData
        );

        toast.success("Atualizado com sucesso");
        handleGetDocuments();
      } catch {
        toast.error("Houve um erro ao tentar atualizar o documento");
      }
    },
  });

  const {
    getRootProps: getRootPropsNewFile,
    getInputProps: getInputPropsNewFile,
  } = useDropzone({
    accept: ["image/*", ".pdf"],
    onDrop: async (acceptedFiles) => {
      try {
        const formData = new FormData();
        formData.append("image", acceptedFiles[0]);

        await api.post(
          `/files?company_id=${params.id}&type_upload=company_document_create&folder_id=${params.folderId}`,
          formData
        );

        toast.success("Adicionado com sucesso");

        handleGetDocuments();
      } catch {
        toast.error("Houve um erro ao tentar adicionar novo documento");
      }
    },
  });

  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header as="h5">
            <span style={{ color: "var(--color-text)" }}>
              Documentos da empresa
            </span>
          </Card.Header>
          <Card.Body>
            {params.folderId !== "null" && (
              <Card
                className="mt-4 mb-4"
                style={{
                  border: "1px dashed var(--slate-400)",
                  boxShadow: "none",
                }}
              >
                <Card.Body>
                  <div
                    {...getRootPropsNewFile({ className: "dropzone" })}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <input {...getInputPropsNewFile()} />
                    <div
                      style={{ color: "var(--gray-400)" }}
                      className="interaction"
                    >
                      <CiImport size={25} className="mr-2" />
                      Clique aqui para adicionar um novo documento em imagem ou
                      PDF
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )}
            <Row>
              {files.map((file) => (
                <Col sm={3} key={file.id}>
                  <Card
                    className="mb-4"
                    style={{
                      boxShadow: "none",
                    }}
                    onClick={() => {
                      setCurrentFile(file.file_id);
                    }}
                  >
                    <Card.Header as="h5">
                      <span> {file.name}</span>
                    </Card.Header>
                    <Card.Body>
                      <div
                        {...getRootProps({ className: "dropzone" })}
                        className="d-flex justify-content-center  align-items-center"
                      >
                        <input {...getInputProps()} />
                        {file.type.includes("image") && (
                          <>
                            <img
                              src={file?.url}
                              className="img-thumbnail"
                              style={{
                                display: "inline-flex",
                                borderRadius: "var(--br-md)",
                                border: "1px solid var(--slate-300)",
                                marginBottom: 8,
                                marginRight: 8,
                                width: 200,
                                height: 200,
                                padding: 4,
                                boxSizing: "border-box",
                              }}
                              alt="Documento"
                            />
                          </>
                        )}
                        {file.type.includes("pdf") && (
                          <>
                            <img
                              src={
                                "https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/1200px-Icon_pdf_file.svg.png"
                              }
                              className="img-thumbnail"
                              style={{
                                display: "inline-flex",
                                borderRadius: "var(--br-md)",
                                border: "1px solid var(--slate-300)",
                                marginBottom: 8,
                                marginRight: 8,
                                width: 200,
                                height: 200,
                                padding: 4,
                                boxSizing: "border-box",
                              }}
                              alt="Documento pdf"
                            />
                          </>
                        )}
                      </div>
                    </Card.Body>
                    <Card.Footer>
                      <button
                        className="btn btn-primary"
                        onClick={() => window.open(file.url)}
                      >
                        Ver arquivo
                      </button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>
          <Card.Footer>
            <Form.Row>
              <Button className="mr-3" type="submit" variant="success">
                Salvar
              </Button>
            </Form.Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

export default DocumentsCompanies;
