import { DefaultValuesFormSolicitationCompany } from "src/types/SolicitationsTypes";
import * as yup from "yup";

const schema = yup.object().shape({
  trainee_name: yup.string().required("O nome do estagiário é obrigatório"),

  trainee_document: yup
    .string()
    .required("O CPF é obrigatório")
    .min(14, "Valor está incompleto"),

  is_higher_education: yup
    .object()
    .nullable()
    .required("Informe se é ensino superior"),

  start_validity: yup
    .date()
    .required("A data de início de vigência é obrigatória")
    .typeError("Data inválida"),

  duration: yup.number().required("A duração em horas é obrigatória"),

  course_id: yup.object().nullable().required("Selecione o curso"),

  educational_institution_id: yup
    .object()
    .nullable()
    .required("Selecione a instituição de ensino"),

  internship_scholarship_value: yup
    .number()
    .required("O valor da bolsa é obrigatório"),

  transportation_assistance_value: yup
    .number()
    .required("O valor do auxílio transporte é obrigatório"),

    [DefaultValuesFormSolicitationCompany.SupervisorCompanyId]: yup
    .object()
    .nullable()
    .required("Informe o supervisor"),


 
});

export default schema;
