import React, { useState, useCallback, useEffect } from "react";
import Card from "react-bootstrap/Card";
import { useHistory } from "react-router-dom";
import Container from "react-bootstrap/Container";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { HiDocument } from "react-icons/hi";

import ComplexTable from "../../../components/ComplexTable";
import api from "src/services/axios";
import { MdDone } from "react-icons/md";

const CompaniesSolicitations: React.FC = () => {
  const history = useHistory();
  
  interface Contract {
    status: boolean;
    trainee_name: string;
    trainee_document: string;
    id: string;
  }

  const [contracts, setContracts] = useState<Contract[]>([]);
  const [filteredContracts, setFilteredContracts] = useState<Contract[]>([]);
  const [filter, setFilter] = useState("todas");

  const getSolicitations = useCallback(() => {
    api.get(`companies/solicitations`).then((response) => {
      setContracts(response.data);
      setFilteredContracts(response.data);
    });
  }, []);

  const handleStatusSolicitation = (id: string) => {
    api.patch(`/solicitations/${id}/status`).then(() => {
      getSolicitations();
    });
  };

  useEffect(() => {
    getSolicitations();
  }, []);

  useEffect(() => {
    if (filter === "realizadas") {
      setFilteredContracts(contracts.filter(contract => contract.status));
    } else if (filter === "nao_realizadas") {
      setFilteredContracts(contracts.filter(contract => !contract.status));
    } else {
      setFilteredContracts(contracts);
    }
  }, [filter, contracts]);

  return (
    <Container fluid>
      <Card style={{ boxShadow: "var(--card-bs)" }}>
        <Card.Header
          as="h5"
          className="d-flex justify-content-between align-items-center color-secondary"
        >
          <div>Solicitações realizadas</div>
          <select
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="todas">Todas</option>
            <option value="realizadas">Realizadas</option>
            <option value="nao_realizadas">Não realizadas</option>
          </select>
        </Card.Header>
        <Card.Body>
          <ComplexTable
            data={filteredContracts}
            columns={[
              {
                dataField: "status",
                text: "Status",
                sort: true,
                filter: false,
                formatter: (_, row) => (
                  <>
                    {row.status ? (
                      <span style={{ color: "green" }}>Realizado</span>
                    ) : (
                      <span style={{ color: "red" }}>Não realizado</span>
                    )}
                  </>
                ),
              },
              {
                dataField: "trainee_name",
                text: "Estagiário",
                sort: true,
                filter: false,
              },
              {
                dataField: "trainee_document",
                text: "Documento",
                sort: true,
                filter: false,
              },
              {
                dataField: "id",
                text: "Ações",
                sort: false,
                headerStyle: {
                  width: "35%",
                },
                formatter: (_, row) => (
                  <>
                    <OverlayTrigger
                      key="term_de_contrato_escola"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>Gerar Contrato</Tooltip>
                      }
                    >
                      <HiDocument
                        onClick={() =>
                          history.push(
                            `/contratos/solicitacoes_realizadas/${row.id}/firmar`
                          )
                        }
                        className="mr-4 color-secondary interaction interaction"
                        size={25}
                      />
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="status_solicitation"
                      placement="bottom"
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          Concluir solicitação
                        </Tooltip>
                      }
                    >
                      <MdDone
                        onClick={() => handleStatusSolicitation(row.id)}
                        className="mr-4 color-secondary interaction interaction"
                        size={25}
                      />
                    </OverlayTrigger>
                  </>
                ),
              },
            ]}
          />
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CompaniesSolicitations;
