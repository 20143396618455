import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Importações do react-bootstrap
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import theme from 'src/styles/theme';
import api from 'src/services/axios';
import { toast } from 'react-toastify';

const DocumentsEmpresas: React.FC = () => {
  const params = useParams();
  const [files, setFiles] = useState<any[]>([]);

  const handleGetDocuments = useCallback(() => {
    api
      .get(`/trainees/documents?trainee_id=${params.id}`)
      .then((response) => {
        setFiles(
          response.data.map((file) => {
            return {
              id: file.id,
              file_id: file.files.id,
              type: file.files.content_type,
              url: file.files.url,
              name: file.files.name,
            };
          })
        );
      })
      .catch(() => {
        toast.error(
          'Houve um erro ao tentar recuperar os documentos desse estágiário'
        );
      });
  }, [params.id]);

  useEffect(() => {
    if (params.id) {
      handleGetDocuments();
    }
  }, [handleGetDocuments, params.id]);

  return (
    <>
      <Container fluid>
        <Card>
          <Card.Header
            style={{
              color: theme.text.colors.dark,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 20px',
            }}
            as='h5'
          >
            <span style={{ color: 'var(--color-text)' }}>Documentos</span>
          </Card.Header>

          <Card.Body>
            {/* Renderizando cada documento */}
            <Row>
              {files.map((file) => (
                <Col md={6} className="mb-4" key={file.id}>
                  <Card className="mb-4 overflow-hidden bs-none">
                    <Card.Title
                      className="pt-4 pb-4 text-center"
                      style={{ background: 'var(--bg-secondary)' }}
                    >
                      <span className="ml-2">{file.name}</span>
                    </Card.Title>
                    <Card.Body className="d-flex flex-column align-items-center">
                      {/* Condicional para exibir imagem ou PDF */}
                      {file.type.includes('image') ? (
                        <img
                          src={file.url}
                          alt={file.name}
                          className="img-thumbnail"
                          style={{
                            borderRadius: 2,
                            border: '2px dashed var(--gray-400)',
                            width: '100%',
                            maxHeight: '300px',
                            objectFit: 'cover',
                          }}
                        />
                      ) : (
                        <img
                          src={
                            'https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/Icon_pdf_file.svg/1200px-Icon_pdf_file.svg.png'
                          }
                          alt={file.name}
                          className="img-thumbnail"
                          style={{
                            borderRadius: 2,
                            border: '1px dashed var(--gray-400)',
                            width: '100px',
                            height: '100px',
                          }}
                        />
                      )}

                      <Button
                        variant="primary"
                        className="mt-3"
                        onClick={() => window.open(file.url)}
                      >
                        Ver arquivo
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Card.Body>

          <Card.Footer>
            <Row>
              <Col className="text-right">
                <Button type="submit" variant="success">
                  Salvar
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Container>
    </>
  );
};

export default DocumentsEmpresas;
