import { SelectOption } from "src/types/GlobalTypes";

type OptionBase = {
    id: string;
    name: string;
}

export function formatToOptionWithIdAndName(data: OptionBase[]): SelectOption[] {
  return data.map((item) => ({
    value: item.id,
    label: item.name,
  }));
}