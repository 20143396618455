import React, { useState, useEffect, useMemo } from "react";
import "../css/calendar.css";
import { Col, Container, Image, Row, Button, Card } from "react-bootstrap";
import { months } from "../data/monthsData"; // Importa os meses
import CalendarDecor2 from "../../../Events/Natal/Calendar-decor-2.png";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

// Função para gerar os estilos dos confetes
const generateConfettiStyles = (): React.CSSProperties => {
  const colors = ["#FFC700", "#FF0000", "#2E8B57", "#1E90FF", "#FF69B4"];
  const size = Math.random() * 10 + 5; // Tamanho aleatório
  const left = Math.random() * 100; // Posição horizontal aleatória
  const duration = Math.random() * 3 + 2; // Duração aleatória

  return {
    backgroundColor: colors[Math.floor(Math.random() * colors.length)],
    width: `${size}px`,
    height: `${size}px`,
    left: `${left}%`,
    animationDuration: `${duration}s`,
    position: "absolute",
    top: "-10%",
  };
};

// Componente do Calendário
export const Calendar: React.FC = () => {
  const [today, setToday] = useState(new Date().getDate());
  const [currentMonthIndex, setCurrentMonthIndex] = useState(
    new Date().getMonth()
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [showConfetti, setShowConfetti] = useState(false); // Estado para controlar os confetes

  const currentMonth = months[currentMonthIndex];

  useEffect(() => {
    const now = new Date();
    const msUntilMidnight =
      new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() -
      now.getTime();
    const timer = setTimeout(() => {
      setToday(new Date().getDate());
    }, msUntilMidnight);
    return () => clearTimeout(timer);
  }, [today]);

  // Calcula o primeiro dia da semana do mês
  const firstDayOfWeek = useMemo(() => {
    return new Date(currentYear, currentMonthIndex, 1).getDay();
  }, [currentYear, currentMonthIndex]);

  // Função para retornar uma mensagem do dia
  const getMessage = () => {
    const isBirthday = currentMonth.birthdays[today] !== undefined;
    const isSpecialDay = currentMonth.specials[today] !== undefined;

    // Se for aniversário
    if (isBirthday) {
      const birthdayPerson = currentMonth.birthdays[today];
      return birthdayPerson.message; // Retorna a mensagem especial de aniversário
    }
    // Se for um dia especial
    else if (isSpecialDay) {
      return currentMonth.specials[today].label;
    }
    // Caso contrário, verifica se existe uma mensagem definida para o dia
    else if (currentMonth.days?.[today]) {
      return currentMonth.days[today].label; // Retorna a mensagem do dia específica
    } else {
      return "Tenha um bom dia, estou corrigindo as mensagens!";
    }
  };

  // Efeito para atualizar os confetes apenas quando necessário
  useEffect(() => {
    const isBirthday = currentMonth.birthdays[today] !== undefined;
    setShowConfetti(isBirthday);
  }, [today, currentMonth]);

  // Navegação de mês
  const goToNextMonth = () => {
    setCurrentMonthIndex((prev) => (prev + 1) % 12);
    if (currentMonthIndex === 11) {
      setCurrentYear((prev) => prev + 1);
    }
  };

  const goToPreviousMonth = () => {
    setCurrentMonthIndex((prev) => (prev - 1 + 12) % 12);
    if (currentMonthIndex === 0) {
      setCurrentYear((prev) => prev - 1);
    }
  };

  // Atualiza a data atual
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setToday(now.getDate());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  // Gera os confetes usando useMemo para otimizar a renderização
  const confettiElements = useMemo(() => {
    const elements: React.ReactNode[] = []; // Define o tipo do array
    for (let i = 0; i < 100; i++) {
      elements.push(
        <div
          key={i}
          className="confetti"
          style={generateConfettiStyles()}
        ></div>
      );
    }
    return elements;
  }, []);

  if (!currentMonth.name) {
    return <h2>Calendário não disponível para o mês atual.</h2>;
  }

  return (
    <Container className="mt-4">
      <Row>
        <Col md={8}>
          <div className="calendar-container">
            <div className="calendar-header">
              <Button variant="secondary" onClick={goToPreviousMonth}>
                <IoIosArrowBack />
              </Button>
              <h1>
                {currentMonth.name} {currentYear}
              </h1>
              <Button variant="secondary" onClick={goToNextMonth}>
                <IoIosArrowForward />
              </Button>
            </div>
            {/* <Image src={CalendarDecor2} className="calendar-decor-2" /> */}

            {/* Exibindo confetes se for aniversário */}
            {showConfetti && (
              <div className="confetti-container">{confettiElements}</div>
            )}

            <div
              className={
                currentMonth.birthdays[today]
                  ? "birthday-message"
                  : "calendar-message"
              }
            >
              <span>
                {currentMonth.birthdays[today]
                  ? `🎉 ${currentMonth.birthdays[today].message} 🎉`
                  : getMessage()}
              </span>
            </div>

            <div className="calendar-grid mb-2">
              <div className="calendar-sem">Dom</div>
              <div className="calendar-sem">Seg</div>
              <div className="calendar-sem">Ter</div>
              <div className="calendar-sem">Qua</div>
              <div className="calendar-sem">Qui</div>
              <div className="calendar-sem">Sex</div>
              <div className="calendar-sem">Sáb</div>
            </div>

            <div className="calendar-grid">
              {/* Espaços vazios para alinhar o primeiro dia */}
              {Array.from({ length: firstDayOfWeek }).map((_, i) => (
                <div key={`empty-${i}`} className="calendar-day empty"></div>
              ))}

              {/* Dias reais do mês */}
              {Array.from(
                { length: currentMonth.daysInMonth(currentYear) },
                (_, i) => i + 1
              ).map((day) => (
                <div
                  key={day}
                  className={`calendar-day ${
                    day === today ? "highlight" : ""
                  } ${
                    currentMonth.birthdays[day] && day === today
                      ? "highlight-birthday"
                      : ""
                  } ${currentMonth.specials[day] ? "special-day" : ""}`}
                  style={{
                    borderColor:
                      currentMonth.birthdays[day]?.label ||
                      currentMonth.specials[day]?.label ||
                      "var(--slate-300)",
                    background:
                      day === today && currentMonth.birthdays[day]
                        ? "linear-gradient(45deg, var(--primary), gold, var(--primary))"
                        : "",
                  }}
                >
                  {day}
                  {currentMonth.birthdays[day] && (
                    <span
                      style={{
                        fontSize: "1.5rem",
                        position: "absolute",
                        top: "-10px",
                        right: "-10px",
                      }}
                    >
                      🎉
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Col>

        <Col md={4}>
          <Card
            style={{
              borderTop: "var(--slate-300) 4px solid",
              borderBottom: "var(--slate-300) 4px solid",
            }}
          >
            <Card.Body>
              <div className="calendar-legend">
                <h2>Aniversários 🎉</h2>
                {Object.entries(currentMonth.birthdays).map(
                  ([day, { label }]) => (
                    <p key={day} className="birthday-entry">
                      <strong>{day}</strong>: {label}
                    </p>
                  )
                )}
                <h2>Dias Especiais 🥳</h2>
                {Object.entries(currentMonth.specials).map(
                  ([day, { label }]) => (
                    <p key={day} className="special-entry">
                      <strong>{day}</strong>: {label}
                    </p>
                  )
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
