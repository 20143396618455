import React from "react";

import {
  FaUserPlus, // Substituto para Adicionar Supervisor
  FaHandshake, // Substituto para Termo de Convênio
  FaFileSignature, // Substituto para Anexo - Termo de Convênio
  FaFolderOpen, // Substituto para Documentos da Empresa
  FaKey, // Substituto para Disponibilizar Credenciais
  FaEdit,
  FaEllipsisV,
  FaTrash,
  FaRedo, // Substituto para Renovar Contrato
  FaUserSlash, // Para Desligamento
  FaFileContract,
} from "react-icons/fa";
import { FaUserTie, FaFileCirclePlus } from "react-icons/fa6";

interface IconProps {
  icon: string;
  color?: string;
  size?: number;
  onClick?(): any;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ icon, size = 25, ...rest }) => {
  // Define cores específicas para cada ícone
  const iconColors = {
    FaUserPlus: "var(--color-text)",
    FaHandshake: "var(--color-text)",
    FaFileSignature: "var(--color-text)",
    FaFolderOpen: "var(--color-text)",
    FaKey: "var(--color-text)",
    FaEdit: "var(--secondary-300)",
    FaEllipsisV: "var(--color-text)",
    FaTrash: "var(--red-regular)",
    FaRedo: "var(--color-text)",
    FaUserSlash: "var(--red-regular)",
    FaUserTie: "var(--color-text)",
    FaFileContract: "var(--color-text)",
    FaFileCirclePlus: "var(--color-text)",
  };

  const icons = new Map([
    [
      "FaUserPlus",
      <FaUserPlus color={iconColors.FaUserPlus} size={size} {...rest} />,
    ],
    [
      "FaHandshake",
      <FaHandshake color={iconColors.FaHandshake} size={size} {...rest} />,
    ],
    [
      "FaFileSignature",
      <FaFileSignature
        color={iconColors.FaFileSignature}
        size={size}
        {...rest}
      />,
    ],
    [
      "FaFolderOpen",
      <FaFolderOpen color={iconColors.FaFolderOpen} size={size} {...rest} />,
    ],
    ["FaKey", <FaKey color={iconColors.FaKey} size={size} {...rest} />],
    ["FaEdit", <FaEdit color={iconColors.FaEdit} size={size} {...rest} />],
    [
      "FaEllipsisV",
      <FaEllipsisV color={iconColors.FaEllipsisV} size={size} {...rest} />,
    ],
    ["FaTrash", <FaTrash color={iconColors.FaTrash} size={size} {...rest} />],
    ["FaRedo", <FaRedo color={iconColors.FaRedo} size={size} {...rest} />],
    [
      "FaUserSlash",
      <FaUserSlash color={iconColors.FaUserSlash} size={size} {...rest} />,
    ],
    [
      "FaUserTie",
      <FaUserTie color={iconColors.FaUserTie} size={size} {...rest} />,
    ],
    [
      "FaFileContract",
      <FaFileContract
        color={iconColors.FaFileContract}
        size={size}
        {...rest}
      />,
    ],
    [
      "FaFileCirclePlus",
      <FaFileCirclePlus
        color={iconColors.FaFileCirclePlus}
        size={size}
        {...rest}
      />,
    ],
  ]);

  return <>{icons.has(icon) ? icons.get(icon) : null}</>;
};

export default Icon;
