import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Label from "../Label";
import ReactSelect from "react-select";
import "./styles.css";

interface SelectProps {
  label: string;
  name: string;
  errorMessage?: string;
  md?: string;
  options: any;
  error?: boolean;
  required?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
}

const Select: React.FC<SelectProps> = ({
  md = 4,
  label,
  errorMessage = "",
  options,
  required = false,
  isClearable = true,
  isMulti = false,
  ...rest
}) => {
  // Estilos customizados para o ReactSelect
  const customStyles = {
    control: (base, state) => ({
      ...base,
      backgroundColor: "var(--bg-secondary)", // Fundo do input
      borderColor: errorMessage
        ? "red" // Vermelho se houver erro
        : state.isFocused
        ? "var(--secondary)" // Cor ao focar
        : "var(--slate-300)", // Cor padrão da borda
      "&:hover": {
        borderColor: "var(--secondary)", // Borda ao passar o mouse
      },
      boxShadow: state.isFocused ? "0 0 0 1px var(--secondary)" : "none", // Sombra quando focado
      color: "var(--color-text)", // Cor do texto no controle
    }),
    container: (base) => ({
      ...base,
      backgroundColor: "var(--bg-secondary)", // Fundo do container
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: "var(--bg-default)", // Fundo do menu
      border: "1px solid var(--slate-300)", // Borda do menu
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isFocused
        ? "var(--bg-secondary)" // Fundo ao focar
        : "var(--bg-default)", // Fundo padrão
      color: "var(--color-text)", // Cor do texto
    }),
    singleValue: (base) => ({
      ...base,
      color: "var(--color-text)", // Cor do texto do valor selecionado
    }),
    placeholder: (base) => ({
      ...base,
      color: "var(--color-placeholder)", // Cor do placeholder
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "var(--color-text)", // Cor do indicador
    }),
    input: (base) => ({
      ...base,
      color: "var(--color-text)", // Cor do texto digitado
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: "var(--bg-secondary)", // Fundo do valor múltiplo
      color: "var(--color-text)", // Cor do texto
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: "var(--color-text)", // Cor do texto no valor múltiplo
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: "var(--color-text)", // Cor do ícone de remoção
      "&:hover": {
        backgroundColor: "var(--bg-danger)", // Fundo ao passar o mouse
        color: "white", // Cor do texto ao passar o mouse
      },
    }),
  };

  return (
    <Form.Group as={Col} md={md} controlId={`form${label}`}>
      <Label text={label} required={required} />

      <ReactSelect
        styles={customStyles}
        placeholder="Selecione"
        options={options}
        isClearable={isClearable}
        isMulti={isMulti}
        {...rest}
      />
      {errorMessage && <div className="invalid-custom">{errorMessage}</div>}
    </Form.Group>
  );
};

export default Select;
