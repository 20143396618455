import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import {
  Container,
  Card,
  Table,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import api from "src/services/axios";

interface Folder {
  id: number;
  name: string;
  fileCount: number;
}

const MyDocuments: React.FC = () => {
  const [folders, setFolders] = useState<Folder[]>([]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchTerm(event.target.value);

  const filteredFolders = folders.filter((folder) =>
    folder.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const fetchFolders = async () => {
    try {
      const response = await api.get(`/companies/my_folders`);
      setFolders(response.data);
    } catch (error) {
      toast.error("Erro ao buscar pastas");
    }
  };

  useEffect(() => {
    fetchFolders();
  }, []);

  return (
    <Container fluid>
      <Card>
        <Card.Header
          className="d-flex justify-content-between align-items-center"
          as="h5"
        >
          <h5>Gerenciador de Pastas</h5>
        </Card.Header>
        <Card.Body>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Pesquisar pastas..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </InputGroup>

          <Table responsive hover>
            <thead>
              <tr>
                <th>Nome</th>
              </tr>
            </thead>
            <tbody>
              {filteredFolders.map((folder) => (
                <tr key={folder.id} style={{ cursor: "pointer" }}>
                  <td>
                    <Link
                      to={`/pastas/${folder.id}`}
                      className="text-decoration-none d-inline"
                    >
                      <i className="bi bi-folder-fill text-warning me-2 mr-3"></i>
                      {folder.name}
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default MyDocuments;
