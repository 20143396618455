import React, { useState } from "react";
import { ProgressBar, Container, ButtonGroup, Button } from "react-bootstrap";
import "../css/progressbarMeta.css";

type ProgressBarMetaProps = {
  value: number; // Quantidade de estagiários cadastrados no sistema
};

const yearGoals = {
  2024: { steps: [700, 750, 800, 850, 900, 950, 1000], finalGoal: 1000 },
  2025: { steps: [1200, 1400, 1600, 1800, 2000], finalGoal: 2000 },
  2026: { steps: [2000, 2200, 2400, 2600, 2800, 3000], finalGoal: 3000 },
};

const ProgressBarMeta: React.FC<ProgressBarMetaProps> = ({ value = 0 }) => {
  const [selectedYear, setSelectedYear] =
    useState<keyof typeof yearGoals>(2025);
  const { steps, finalGoal } = yearGoals[selectedYear];

  const calculatePercentage = (value: number): number => {
    const percentage = (value / finalGoal) * 100;
    return percentage > 100 ? 100 : percentage;
  };

  const nextStep = steps.find((step) => value < step);

  return (
    <Container>
      <div className="progress-meta-container">
        <div className="d-flex mb-3 justify-content-between align-items-center">
          <h5 className="progress-title">
            Progresso da Meta de {selectedYear}
          </h5>

          <ButtonGroup>
            {Object.keys(yearGoals).map((year) => (
              <Button
                key={year}
                variant={
                  parseInt(year) === selectedYear ? "primary" : "secondary"
                }
                onClick={() =>
                  setSelectedYear(parseInt(year) as keyof typeof yearGoals)
                }
              >
                {year}
              </Button>
            ))}
          </ButtonGroup>
        </div>

        <div className="progress-wrapper">
          <ProgressBar
            now={calculatePercentage(value)}
            animated
            className="progress-bar-meta"
          />
          <span className="progress-percentage">
            {calculatePercentage(value).toFixed(0)}%
          </span>

          {steps.map((step, index) => (
            <div
              key={index}
              className={`progress-step-marker ${
                value >= step ? "reached" : ""
              }`}
              style={{ left: `${(step / finalGoal) * 100}%` }}
            >
              <div className="step-marker-dot">
                <span className="step-number">{step}</span>
              </div>
              {step === nextStep && (
                <div className="step-balloon">Próxima Etapa: {step}!</div>
              )}
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default ProgressBarMeta;
