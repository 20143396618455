import React, { useCallback } from "react";

import BootstrapTable from "react-bootstrap-table-next";

import { FaSortDown, FaSortUp } from "react-icons/fa";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import "./styles.css";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";
import { CSSProperties } from "styled-components";
import { NoDataMessage } from "../NoDataMessage/index";

const configSortCaret = (order) => {
  let icon;
  if (!order) return;
  if (order === "asc") icon = <FaSortUp color={"#CCC"} />;
  if (order === "desc") icon = <FaSortDown color={"#CCC"} />;
  return <span>{icon}</span>;
};

const rowStyle = () => {
  const style: CSSProperties = {};

  style.fontSize = "14px";
  style.color = "var(--color-text)";
  style.height = "50px";
  style.border = "1px solid var(--secondary-light)";

  return style;
};

function Table({ data, columns, keyField = "id", ...rest }) {
  const handleHeaderFormatter = useCallback(
    (column, colIndex, { sortElement, filterElement }) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span
            style={{
              padding: "0.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <span style={{ marginRight: 5 }}>{column.text}</span>

            {sortElement}
          </span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {filterElement}
          </div>
        </div>
      );
    },
    []
  );

  const options = {
    paginationSize: 5,
    sizePerPage: 20,
    pageStartIndex: 0,
    alwaysShowAllBtns: false, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "Primeiro",
    prePageText: "Anterior",
    nextPageText: "Próximo",
    lastPageText: "Último",
    nextPageTitle: "1º página",
    prePageTitle: "Pre page",

    disablePageTitle: true,

    custom: true,
    totalSize: data.length,
    paginationTotalRenderer: (from, to, size) => (
      <span
        style={{ fontSize: 12 }}
        className="react-bootstrap-table-pagination-total"
      >
        {`Exibindo ${from} a ${to} de um total de ${size} resultados`}
      </span>
    ),
  };

  return (
    <PaginationProvider pagination={paginationFactory(options)}>
      {({ paginationProps, paginationTableProps }) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: " center",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <PaginationTotalStandalone {...paginationProps} />
            <PaginationListStandalone {...paginationProps} />
          </div>
          <BootstrapTable
            keyField={keyField}
            data={data}
            columns={columns.map((column) => {
              return {
                ...column,
                align: "center",
                headerFormatter: handleHeaderFormatter,
                headerStyle: {
                  ...column.headerStyle,
                  background: "var(--slate-950)",
                  border: "var(--slate-600) 1px solid",
                  color: "var(--white)",
                  fontSize: 14,
                },
                filter:
                  column.filter &&
                  textFilter({
                    placeholder: ` `,
                    className: "input-filter",
                  }),
                sortCaret: configSortCaret,
              };
            })}
            {...paginationTableProps}
            bootstrap4
            filter={filterFactory()}
            bordered={false}
            condensed
            noDataIndication={<NoDataMessage />}
            defaultSorted={[
              {
                dataField: "id",
                order: "asc",
              },
            ]}
            rowStyle={rowStyle}
            {...rest}
          />
        </>
      )}
    </PaginationProvider>
  );
}

export default Table;
