import React, { useState, useEffect } from "react";
import Swal, { SweetAlertIcon } from "sweetalert2";

const alertSteps: { title: string; text: string; icon: SweetAlertIcon }[] = [
  {
    title: "Atenção Necessária",
    text: "Sei que preciso fazer um curso na área para poder estagiar.",
    icon: "info",
  },
  {
    title: "Informações sobre o Curso",
    text: "O curso é dividido em módulos semanais, e tenho de segunda a quarta para assistir às aulas e entregar a atividade.",
    icon: "info",
  },
  {
    title: "Compromisso com as Aulas",
    text: "Eu tenho que acompanhar as aulas e me comprometer, caso contrário, posso ter desconto na bolsa e, se isso acontecer várias vezes, posso até ser desligado do estágio.",
    icon: "success",
  },
  
  {
    title: "Esse site armazena dados",
    text: "Nós guardamos os seus dados para manutenção de contratos gerados na plataforma. Ao utilizar os nossos serviços, você declara o seu EXPRESSO CONSENTIMENTO para coletarmos, tratarmos e armazenarmos dados sobre você quando julgarmos necessários.",
    icon: "info",
  },
  
];

const AlertSequence: React.FC = () => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (step < alertSteps.length) {
      showAlert(step);
    }
  }, [step]);

  const showAlert = async (index: number) => {
    const { title, text, icon } = alertSteps[index];

    await Swal.fire({
      title,
      text,
      icon,
      html: `
      <div clas="d-flex">
      <p>${text}</p>
        <button id="next-btn" class="swal2-confirm swal2-styled" disabled style="font-size: 18px; padding: 10px; margin-top: 15px;">
          Aguarde 10 segundos...
        </button>
        </div>
      `,
      allowOutsideClick: false,
      showConfirmButton: false,
      backdrop: "rgba(0, 0, 0, 0.85)", // Fundo escurecido para maior foco
      didOpen: () => {
        const button = document.getElementById("next-btn") as HTMLButtonElement;
        let countdown = 10;

        const updateButtonText = () => {
          if (countdown > 0) {
            button.innerText = `Aguarde ${countdown} segundos...`;
            countdown -= 1;
            setTimeout(updateButtonText, 1000);
          } else {
            button.innerText =
              index === alertSteps.length - 1
                ? "Concordo com tudo"
                : "Eu concordo";
            button.disabled = false;
            button.onclick = () => handleNextStep(index);
          }
        };

        updateButtonText();
      },
    });
  };

  const handleNextStep = (index: number) => {
    if (index < alertSteps.length - 1) {
      setStep((prev) => prev + 1);
    } else {
      Swal.close();
    }
  };

  return null; // Sem necessidade de renderizar algo
};

export default AlertSequence;
