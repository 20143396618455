import { GetReportOcorrenciaResponse } from "src/types/ReportTypes";
import api from "./axios";

class ReportService {
  private endpoint = "/reports";

  async getData(contractId: string, type: string) {
    const { data } = await api.get(this.endpoint, {
      params: { contractId, type },
    });
    return data as  GetReportOcorrenciaResponse;
  }
}

const reportService = new ReportService();

export default reportService;
